import React, { Component } from "react";

class PageFooter extends Component {
  render() {
    return (
      <div className="page-footer">
        <div className="page-footer__help-text">
          <h3 className="page-footer__help-heading">Need help?</h3>
          If you have questions or need help filling out this form, please call
          the VA Veteran Widget Product Office (VWPO) at{" "}
          <a href="tel:800-827-1000">800-827-1000</a>.<br />
          If you have hearing loss, call TTY: 711.
        </div>
        <div className="page-footer__subtitle">
          Widget Claims Submission Tool
          {this.props.displayVersionNumber && " — Version 1.0"}
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default PageFooter;
