import React from "react";

const VeteransAffairsBreadcrumbs = () => {
  return (
    <nav
      aria-label="Breadcrumb"
      aria-live="polite"
      className="veterans-affairs-breadcrumbs"
    >
      <ul className="veterans-affairs-breadcrumbs__list">
        <li>
          <a href="https://www.va.gov/">Home</a>
        </li>
        <li>
          <a href="/">Widget Claims</a>
        </li>
      </ul>
    </nav>
  );
};

export default VeteransAffairsBreadcrumbs;
