import React from "react";
import headerLogo from "../../header-logo.png";

const VeteransAffairsHeader = () => {
  return (
    <div className="veterans-affairs-header">
      <section className="veterans-affairs-header__site-guidance">
        <div className="veterans-affairs-header__site-guidance-text">THIS IS A DEMO WEBSITE FOR VA T4NG ON-RAMP EVALUATION PURPOSES ONLY</div>
      </section>
      {/* <section className="site-guidance"></section> */}
      <header className="header">
        <div
          className="row va-flex usa-grid usa-grid-full"
          id="va-header-logo-menu"
        >
          <div className="va-header-logo-wrapper">
            <a href="/" className="va-header-logo">
              <img src={headerLogo} alt="Go to VA.gov" />
            </a>
          </div>
          <div id="va-nav-controls"></div>
          <div id="login-root" className="vet-toolbar"></div>
        </div>
        <div className="usa-grid usa-grid-full">
          <div id="menu-rule" className="usa-one-whole"></div>
          <div id="mega-menu">
            <div className="login-container">
              <div className="row va-flex">
                <div id="vetnavDDD" role="navigation">
                  <div id="vetnav-menu">
                    <li>
                      <a href="/" className="navigation-item">
                        Widget Claims Submission Tool
                      </a>
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default VeteransAffairsHeader;
