/* eslint react/prop-types: 0 */
import React from "react";
import { Route } from "react-router";
import SiteHeader from "../common/navigation/headers/SiteHeader";

const RouteWithHeader = ({ title, render: Component }) => {
  return (
    <Route
      render={(matchProps) => [
        <SiteHeader title={title} key="application-header" />,
        <Component key="application-content" {...matchProps} />
      ]}
    />
  );
};

export default RouteWithHeader;
