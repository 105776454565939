import React, { useEffect } from "react";
import ErrorableFileInput from "@department-of-veterans-affairs/formation-react/ErrorableFileInput";
import ErrorableTextInput from "@department-of-veterans-affairs/formation-react/ErrorableTextInput";
import ErrorableCheckbox from "@department-of-veterans-affairs/formation-react/ErrorableCheckbox";
import useForm from "./useForm";
import bem from "easy-bem";

const b = bem("form-component");

const required_fields = [
  "veteranFirstName",
  "veteranLastName",
  "fileNumber",
  "zipCode"
];

const text_inputs = {
  veteranFirstName: {
    label: "Your first name",
    placeholder: " ",
    charMax: 150
  },
  veteranLastName: {
    label: "Your last name",
    placeholder: " ",
    charMax: 150
  },
  fileNumber: {
    label: "Social Security number / VA file number (no hyphens)",
    placeholder: " ",
    charMax: 9
  }
};

const FormComponent = ({ disabled, onSubmit }) => {
  const {
    values,
    errors,
    formIsValid,
    handleChange,
    handleSubmit,
    validateValues,
    fileUpload,
    setFileUpload
  } = useForm(required_fields, onSubmit);

  const selectPDF = (e) => {
    const tempFileUpload = {};
    tempFileUpload.length = e.length;
    for (let item = 0; item < e.length; item++) {
      tempFileUpload[item] = e[item];
    }
    if (tempFileUpload.length) setFileUpload(tempFileUpload);
  };

  // validateValues when values.zipCode is undefined and values is updated
  useEffect(() => {
    if (values.zipCode !== undefined && values.isNonUSZip)
      validateValues(values);
  }, [values, validateValues]);

  const TextInputs = Object.keys(text_inputs).map((field) => {
    const props = text_inputs[field];
    return (
      <ErrorableTextInput
        {...props}
        key={field}
        name={field}
        required={required_fields.includes(field)}
        additionalClass={`${b("textinput")}`}
        errorMessage={errors[field] || null}
        onValueChange={(e) => {
          handleChange(field, e);
        }}
        field={values[field] || ""}
      />
    );
  });

  const fileNames = [];
  if (fileUpload) {
    for (let i = 0; i < fileUpload.length; i++) {
      fileNames.push(fileUpload[i].name);
    }
  }

  return (
    <div className={`${b()} `}>
      <form onSubmit={handleSubmit} className={`${b("form")}`}>
        <fieldset className={`${b("fieldset")}`}>
          {TextInputs}
          <ErrorableCheckbox
            label={"I reside outside of the United States"}
            onValueChange={(value) =>
              handleChange("isNonUSZip", { dirty: false, value })
            }
            id="zipCode"
            title="Non US Address"
          />
          {values.isNonUSZip ? null : (
            <ErrorableTextInput
              key={"zipCode"}
              name={"zipCode"}
              label="Postal code"
              placeholder=""
              required={
                required_fields.includes("zipCode") && !values.isNonUSZip
              }
              additionalClass={`${b("textinput")}`}
              charMax={10}
              errorMessage={errors["zipCode"] || null}
              onValueChange={(e) => {
                handleChange("zipCode", e);
              }}
              field={values["zipCode"] || ""}
              disabled={values.isNonUSZip}
            />
          )}

          <ErrorableFileInput
            name="PDFUploader"
            className="usa-button"
            label="Your completed Form T4NG"
            multiple={true} // For now.
            buttonText="Select your completed Form T4NG PDF"
            accept="application/pdf"
            onChange={(e) => selectPDF(e)}
            required={true}
          />
          <div className="feature">
            {!fileNames[0] ? (
              <span>You have not selected a file.</span>
            ) : (
              fileNames.map((fileName) => (
                <div key={fileName} className={b("selected-file")}>
                  {fileName}
                </div>
              ))
            )}
          </div>
          <div className={b("subtitle")}>
            Note: Your document must be submitted in PDF format.
          </div>
        </fieldset>
        <div className={b("confirmation")}>
          <span>
            Do you want to submit your Widget claim for processing using the
            Widget Claims Submission Tool?
            <br />
          </span>
        </div>

        <input
          type="submit"
          className={`${b("submit-button")} usa-button`}
          value="Submit your Widget claim"
          disabled={!formIsValid || fileUpload === null || disabled}
        />
      </form>
    </div>
  );
};

export default FormComponent;
