import config from "../config";

export class T4NGApiError extends Error {
  constructor(message) {
    super(message);
    this.name = "T4NGApiError";
  }
}

export class T4NGApi {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  async initiateDocumentUpload() {
    const res = await fetch(`${this.baseUrl}/uploads`, {
      method: "post"
    });
    if (!res.ok) {
      throw new T4NGApiError(`initiateDocumentUpload failed: ${res.status}`);
    }
    return res.json();
  }

  async checkDocumentStatus(guid) {
    const res = await fetch(`${this.baseUrl}/uploads/${guid}`, {
      method: "get",
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: -1
      }
    });
    if (!res.ok) {
      if (res.status === 404) {
        throw new T4NGApiError(
          `We are sorry. We can't find this Confirmation / Tracking code in our system.`
        );
      } else {
        throw new T4NGApiError(`We are sorry. We've run into a problem.`);
      }
    }
    return res.json();
  }

  async uploadDocument(location, files, metadata) {
    const formdata = new FormData();
    for (let i = 0; i < files.length; i++) {
      formdata.append("content", files[i]);
    }
    formdata.append("metadata", JSON.stringify(metadata));
    formdata.append("location", location);
    const res = await fetch(`${this.baseUrl}/upload-pdf`, {
      method: "put",
      body: formdata
    });
    if (!res.ok) {
      throw new T4NGApiError(`uploadDocument failed: ${res.status}`);
    }
    return res;
  }
}

export default new T4NGApi(config.t4ngApiBaseUrl);
