import React from "react";

const PageIntroduction = () => {
  return (
    <div className="page-introduction">
      <h1 className="page-introduction__title">File your Widget claim</h1>
      <div className="va-introtext">
        <p>
          With our VA Widget Claims Submission Tool, you can file a Widget claim
          by providing basic identifying information and submitting your
          completed Widget Claim Form (VA Form T4NG). You can also check the
          status of your Form T4NG submission by providing your Confirmation /
          Tracking code.
        </p>
      </div>
    </div>
  );
};

export default PageIntroduction;
