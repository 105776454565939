import React, { useState } from "react";
import bem from "easy-bem";
import pdf from "../T4NG.pdf";
import ClaimStatusCard from "./ClaimStatusCard";

import t4ngApi from "../services/t4ng-api";
import FormComponent from "./FormComponent";

const b = bem("submit-new-document");

const SubmitNewDocument = ({ setFormIsSubmitted }) => {
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [
    checkDocumentStatusResponse,
    setCheckDocumentStatusResponse
  ] = useState(null);
  const [
    checkDocumentStatusInProgress,
    setCheckDocumentStatusInProgress
  ] = useState(false);
  const [checkDocumentStatusError, setCheckDocumentStatusError] = useState(
    null
  );
  const checkDocumentStatus = (documentId) => {
    if (!checkDocumentStatusInProgress) {
      setCheckDocumentStatusInProgress(true);
      return t4ngApi
        .checkDocumentStatus(documentId)
        .then((response) => {
          setCheckDocumentStatusResponse(response);
          setCheckDocumentStatusInProgress(false);
          setCheckDocumentStatusError(null);
          return response;
        })
        .catch((err) => {
          setCheckDocumentStatusResponse(null);
          setCheckDocumentStatusInProgress(false);
          setCheckDocumentStatusError(err);
          return null;
        });
    }
  };

  const [uploadDocumentResponse, setUploadDocumentResponse] = useState(null);
  const [uploadDocumentInProgress, setUploadDocumentInProgress] = useState(
    false
  );
  const [uploadDocumentError, setUploadDocumentError] = useState(null);

  const uploadDocument = (location, files, metadata) => {
    if (location) {
      setUploadDocumentInProgress(true);
      return t4ngApi
        .uploadDocument(location, files, metadata)
        .then((response) => {
          setUploadDocumentResponse(response);
          setUploadDocumentInProgress(false);
          setUploadDocumentError(null);
        })
        .catch((err) => {
          setUploadDocumentResponse(null);
          setUploadDocumentInProgress(false);
          setUploadDocumentError(err);
          throw err;
        });
    } else {
      setUploadDocumentError({
        message:
          "The backend services failed to provide a location to upload your documents. Please contact support."
      });
    }
  };

  const [
    initiateDocumentUploadResponse,
    setInitiateDocumentUploadResponse
  ] = useState(null);
  const [
    initiateDocumentUploadInProgress,
    setInitiateDocumentUploadInProgress
  ] = useState(false);
  const [
    initiateDocumentUploadError,
    setInitiateDocumentUploadError
  ] = useState(null);
  const initiateDocumentUpload = () => {
    if (!initiateDocumentUploadInProgress) {
      setInitiateDocumentUploadInProgress(true);
      return t4ngApi
        .initiateDocumentUpload()
        .then((response) => {
          setInitiateDocumentUploadResponse(response);
          setInitiateDocumentUploadInProgress(false);
          setInitiateDocumentUploadError(null);
          return response;
        })
        .catch((err) => {
          setInitiateDocumentUploadResponse(null);
          setInitiateDocumentUploadInProgress(false);
          setInitiateDocumentUploadError(err);
          return null;
        });
    }
    return null;
  };

  const checkStatusUntilReceived = (documentId, tries) => {
    if (currentTimeout) {
      clearTimeout(currentTimeout);
      setCurrentTimeout(null);
    }
    const checkPromise = checkDocumentStatus(documentId);
    if (checkPromise) {
      checkPromise.then((res) => {
        if (
          res?.data?.attributes?.status === "pending" ||
          res?.data?.attributes?.status === "uploaded"
        ) {
          if (tries < 10) {
            const currTimeout = setTimeout(() => {
              checkStatusUntilReceived(documentId, tries + 1);
            }, 3000);
            setCurrentTimeout(currTimeout);
          } else {
            const message = `We're sorry. We're having trouble confirming that your submission was received.`;
            setCheckDocumentStatusError(null);
            setCheckDocumentStatusResponse({
              data: {
                attributes: {
                  status: "error",
                  detail: message
                }
              }
            });
          }
        }
      });
    }
  };

  const onSubmit = (formValues, files) => {
    // Clear all previous state
    if (currentTimeout) {
      clearTimeout(currentTimeout);
      setCurrentTimeout(null);
    }
    setCheckDocumentStatusError(null);
    setCheckDocumentStatusResponse(null);
    setUploadDocumentError(null);
    setUploadDocumentResponse(null);
    setInitiateDocumentUploadError(null);
    setInitiateDocumentUploadResponse(null);
    setFormIsSubmitted(true);

    const initiatePromise = initiateDocumentUpload();
    if (initiatePromise) {
      initiatePromise
        .then((documentStatus) => {
          return Promise.all([
            documentStatus,
            uploadDocument(
              documentStatus?.data?.attributes?.location,
              files,
              formValues
            )
          ]);
        })
        .then(([documentStatus]) => {
          return checkStatusUntilReceived(
            documentStatus?.data?.attributes?.guid,
            0
          );
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const loading = initiateDocumentUploadInProgress || uploadDocumentInProgress;
  const currentStatus = checkDocumentStatusResponse?.data?.attributes?.status;
  const dontResubmitBecauseYouAlreadyDidAndItsNotAnError =
    !!currentStatus && currentStatus !== "error";
  return (
    <main className={b()}>
      <h2 className={b("title")}>Submit your Widget claim</h2>
      <h4>What documents and information do I need to submit?</h4>
      <ul>
        <li>Your first name and last name</li>
        <li>Social Security number or VA file number</li>
        <li>
          Postal code
          <br />
          Note: Postal code not required for applicants residing outside of the
          United States.
        </li>
        <li>
          Your completed Form T4NG in PDF format.
          <br />
          <a href={pdf} target="_blank" rel="noopener noreferrer">
            Download a blank copy of Form T4NG.
          </a>
        </li>
      </ul>
      <FormComponent
        disabled={
          loading ||
          checkDocumentStatusInProgress ||
          dontResubmitBecauseYouAlreadyDidAndItsNotAnError
        }
        onSubmit={onSubmit}
      />
      {loading && <ClaimStatusCard status={`loading`} />}
      {(uploadDocumentError !== null ||
        initiateDocumentUploadError !== null) && (
        <ClaimStatusCard
          status={"error"}
          error={"Something went wrong on our end. Please try again later."}
        />
      )}
      {!checkDocumentStatusInProgress && checkDocumentStatusError !== null && (
        <ClaimStatusCard
          status={`error`}
          error={checkDocumentStatusError?.message ?? "Unknown Error"}
        />
      )}
      {uploadDocumentResponse !== null &&
        (checkDocumentStatusInProgress ||
          checkDocumentStatusResponse !== null) && (
          <span>
            {checkDocumentStatusResponse?.data?.attributes?.status ? (
              <ClaimStatusCard
                showPendingStatusAsUploading={true}
                status={checkDocumentStatusResponse?.data?.attributes?.status}
                error={checkDocumentStatusResponse?.data?.attributes?.detail}
              >
                <span className={b("ticket-label")}>
                  <br />
                  <br />
                  Your Confirmation / Tracking code:
                </span>
                <div className={b("ticket-value")}>
                  <span>
                    {initiateDocumentUploadResponse?.data?.attributes?.guid ??
                      "Something went wrong"}
                  </span>
                </div>
                <span className={b("ticket-label")}>
                  <br />
                  Potential next steps after I file my VA claim
                </span>
                <p>
                  <a
                    className={b("information-link")}
                    href="https://www.va.gov/disability/after-you-file-claim/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Find out what happens after you file
                  </a>
                </p>
                <p>
                  You don’t need to do anything unless we send you a letter
                  asking for more information.
                </p>
              </ClaimStatusCard>
            ) : (
              <ClaimStatusCard status={`preparingToUpload`} />
            )}
          </span>
        )}
    </main>
  );
};

export default SubmitNewDocument;
