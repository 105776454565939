import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.scss";
import App from "./components/App/App";
import { BrowserRouter, Route } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* We put this <Route/> here, rather than just having <App/> here and having App contain all the routes, in order to avoid this problem:
      https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md */}
      <Route path="/" component={App} />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
