import React from "react";
import vaLogoWhite from "../../va-logo-white.png";

const VeteransAffairsFooter = () => {
  return (
    <footer className="footer" role="contentinfo">
      <div id="footerNav">
        <div>
          <div className="footer-inner">
            <div className="usa-grid usa-grid-full footer-banner">
              <a href="/" className="va-footer-logo" title="Go to VA.gov">
                <img src={vaLogoWhite} alt="VA logo" />
              </a>
            </div>
            <div className="usa-grid usa-grid-full va-footer-links-bottom">
              <ul>
                <li>
                  <a
                    href="https://www.section508.va.gov"
                    rel="noopener noreferrer"
                  >
                    Accessibility
                  </a>
                </li>
                <li>
                  <a href="https://www.va.gov/orm/NOFEAR_Select.asp">
                    No FEAR Act Data
                  </a>
                </li>
                <li>
                  <a href="https://www.va.gov/oig/">
                    Office of Inspector General
                  </a>
                </li>
                <li>
                  <a href="https://www.va.gov/opa/Plain_Language.asp">
                    Plain language
                  </a>
                </li>
                <li>
                  <a href="https://www.va.gov/privacy-policy/">
                    Privacy, policies, and legal information
                  </a>
                </li>
                <li>
                  <a href="https://www.va.gov/privacy/">VA Privacy Service</a>
                </li>
                <li>
                  <a href="https://www.va.gov/foia/">
                    Freedom of Information Act (FOIA)
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.usa.gov/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    USA.gov
                  </a>
                </li>
                <li>
                  <a href="https://www.va.gov/performance-dashboard/">
                    VA performance dashboard
                  </a>
                </li>
                <li>
                  <a href="https://www.va.gov/veterans-portrait-project/">
                    Veterans Portrait Project
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default VeteransAffairsFooter;
