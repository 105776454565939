import PropTypes from "prop-types";
import React, { Component } from "react";

class _404Page extends Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
    })
  };
  render() {
    // const { location } = this.props;

    return (
      <div className="_404-page__container">
        <div className="_404-page__sidebars" />
        <div className="_404-page__center">
          <div className="_404-page__title">Not Found.</div>
          <div className="auth-page__subtitle">This is not a valid page.</div>
        </div>
        <div className="_404-page__sidebars" />
      </div>
    );
  }
}

export default _404Page;
