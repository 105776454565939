import React, { useState, useEffect } from "react";
import bem from "easy-bem";
import ClaimStatusCard from "./ClaimStatusCard";

import t4ngApi from "../services/t4ng-api";

const b = bem("status-checker");

const StatusChecker = ({ formIsSubmitted }) => {
  const [documentId, setDocumentId] = useState("");

  useEffect(() => {
    setDocumentId("");
    setCheckDocumentStatusResponse(null);
    setCheckDocumentStatusError(null);
  }, [formIsSubmitted]);

  const [
    checkDocumentStatusResponse,
    setCheckDocumentStatusResponse
  ] = useState(null);
  const [
    checkDocumentStatusInProgress,
    setCheckDocumentStatusInProgress
  ] = useState(false);
  const [checkDocumentStatusError, setCheckDocumentStatusError] = useState(
    null
  );
  const checkDocumentStatus = () => {
    if (!checkDocumentStatusInProgress) {
      setCheckDocumentStatusInProgress(true);
      return t4ngApi
        .checkDocumentStatus(documentId)
        .then((response) => {
          setCheckDocumentStatusResponse(response);
          setCheckDocumentStatusInProgress(false);
          setCheckDocumentStatusError(null);
          return response;
        })
        .catch((err) => {
          setCheckDocumentStatusResponse(null);
          setCheckDocumentStatusInProgress(false);
          setCheckDocumentStatusError(err);
          return null;
        });
    }
  };

  return (
    <aside className={b()}>
      <h2 className={b("heading")}>Have you filed a VA Widget claim before?</h2>
      <span className={b("description")}>
        Enter your Confirmation / Tracking code to check your Form T4NG
        submission status.
      </span>
      <label
        className={b("tracking-code-label")}
        htmlFor="confirmation-tracking-code-input"
      >
        Confirmation / Tracking code
      </label>
      <input
        type="text"
        onChange={(e) => setDocumentId(e.target.value)}
        value={documentId}
        placeholder=""
        className={b("tracking-code-input")}
        name="confirmation-tracking-code-input"
        id="confirmation-tracking-code-input"
      />
      <button className={b("upload-button")} onClick={checkDocumentStatus}>
        Check submission status
      </button>
      {checkDocumentStatusInProgress && <ClaimStatusCard status={`loading`} />}
      {!checkDocumentStatusInProgress && checkDocumentStatusError !== null && (
        <ClaimStatusCard
          status={`error`}
          error={checkDocumentStatusError?.message ?? "Unknown Error"}
        />
      )}
      {/* {checkDocumentStatusResponse !== null && (
        <div className={b("result")}>
          Status:{" "}
          {checkDocumentStatusResponse?.data?.attributes?.status ??
            "Something went wrong"}
        </div>
      )} */}
      {checkDocumentStatusResponse !== null && !checkDocumentStatusInProgress && (
        <span>
          {checkDocumentStatusResponse?.data?.attributes?.status ? (
            <ClaimStatusCard
              showPendingStatusAsUploading={false}
              status={checkDocumentStatusResponse?.data?.attributes?.status}
              error={checkDocumentStatusResponse?.data?.attributes?.detail}
            >
              <>
                <br />
                <span className={b("ticket-label")}>
                  <br />
                  Potential next steps after I file my VA claim
                </span>
                <p>
                  <a
                    className={b("information-link")}
                    href="https://www.va.gov/disability/after-you-file-claim/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Find out what happens after you file
                  </a>
                </p>
                <p>
                  You don’t need to do anything unless we send you a letter
                  asking for more information.
                </p>
              </>
            </ClaimStatusCard>
          ) : (
            <ClaimStatusCard status={`preparingToUpload`} />
          )}
        </span>
      )}
    </aside>
  );
};

export default StatusChecker;
