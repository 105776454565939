// Validation rules throw an error if they fail
// This makes it so we can catch the error that failed first
const validation_rules = {
  veteranFirstName: (value) => true,
  veteranLastName: (value) => true,
  isNonUSZip: (value) => true,
  fileNumber: (value) => {
    let re = /^\d{8,9}$/;
    if (!re.test(value)) {
      throw new Error(
        "Social Security number / VA file number should be 8 or 9 digits"
      );
    }
    return true;
  },
  zipCode: (value) => {
    let re = /^(\d{5})(-\d{4})?$/;
    if (!re.test(value)) {
      throw new Error('Zip Code must be in format "XXXXX" or "XXXXX-XXXX".');
    }
    return true;
  }
};

export const validateField = ({ key, value }) => {
  // initiation validationResponse
  const validationResponse = { isValid: true, error: null };
  try {
    if (validation_rules[key](value)) {
      // Validation passes and error is `null`
      return validationResponse;
    }
  } catch (error) {
    // Error thrown during validation
    validationResponse.error = error.message;
    validationResponse.isValid = false;
  }
  return validationResponse;
};

const validateAllFields = (values) => {
  const validationResponse = { errors: {} };

  // Loop through values and expand the error object
  Object.keys(values).map((key) => {
    validationResponse.errors = {
      ...validationResponse.errors,
      [key]: validateField({ key, value: values[key] })
    };
    return true;
  });

  // Set isValid to true if all fields are valid
  // Set isValid to false if one field is invalid
  validationResponse.isValid = !Object.keys(validationResponse.errors).some(
    (k) => !validationResponse.errors[k].isValid
  );
  return validationResponse;
};
export default validateAllFields;
