import React, { Component } from "react";

const status = {
  loading: {
    title: "Loading",
    description: "Loading. Please wait."
  },
  preparingToUpload: {
    title: "Your submission is being prepared",
    description:
      "Preparing to upload your submission. Do not navigate away from this page."
  },
  pending: {
    title: "Your submission is pending",
    description: "You have not yet submitted your document."
  },
  uploading: {
    title: "Your submission is uploading",
    description:
      "Uploading your submission. Do not navigate away from this page."
  },
  uploaded: {
    title: "Your submission has been uploaded",
    description:
      "Your submission has been successfully uploaded. You may navigate away from this page."
  },
  received: {
    title: "Your submission was received",
    description:
      "Your submission has been received. You may navigate away from this page."
  },
  processing: {
    title: "Your submission is in processing",
    description: "We are processing your submission."
  },
  success: {
    title: "Your submission was processed",
    description: "We have processed your submission."
  },
  vbms: {
    title: "Your submission has been processed",
    description: "Your submission has been submitted for review."
  },
  expired: {
    title: "Your submission has expired",
    description:
      "Your submission was not submitted before it expired. You will need to submit your form again."
  },
  error: {
    title: "Error",
    description: "Error"
  }
};

class ClaimStatusCard extends Component {
  render() {
    const { error } = this.props;
    return (
      <div
        className={`claim-status-card claim-status-card--${
          this.props.showPendingStatusAsUploading &&
          this.props.status === "pending"
            ? "uploading"
            : this.props.status
        }`}
      >
        <div className="usa-alert-body">
          <h3 className="usa-alert-heading">
            {
              status[
                this.props.showPendingStatusAsUploading &&
                this.props.status === "pending"
                  ? "uploading"
                  : this.props.status
              ]?.title
            }
          </h3>
          {error ? (
            <div className="usa-alert-text">
              <span>{error}</span>
              {this.props.children}
              <div>
                <br />
                Please call the VA Veteran Widget Product Office (VWPO) at{" "}
                <a href="tel:800-827-1000">800-827-1000</a>.<br />
                If you have hearing loss, call TTY: 711.
              </div>
            </div>
          ) : (
            <div className="usa-alert-text">
              <span>
                {
                  status[
                    this.props.showPendingStatusAsUploading &&
                    this.props.status === "pending"
                      ? "uploading"
                      : this.props.status
                  ]?.description
                }
              </span>
              {this.props.children}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ClaimStatusCard;
