import React, { useState } from "react";
import VeteransAffairsBreadcrumbs from "../va/VeteransAffairsBreadcrumbs";
import VeteransAffairsFooter from "../va/VeteransAffairsFooter";
import SubmitNewDocument from "../SubmitNewDocument";
import StatusChecker from "../StatusChecker";
import PageIntroduction from "../PageIntroduction";
import PageFooter from "../PageFooter";

const LandingPage = () => {
  const [formIsSubmitted, setFormIsSubmitted] = useState(false);
  return (
    <div className="landing-page">
      <VeteransAffairsBreadcrumbs />
      <div className="landing-page__content">
        <PageIntroduction />
        <StatusChecker formIsSubmitted={formIsSubmitted} />
        <SubmitNewDocument setFormIsSubmitted={setFormIsSubmitted} />
      </div>
      <PageFooter displayVersionNumber={true}>
        <VeteransAffairsFooter />
      </PageFooter>
    </div>
  );
};

export default LandingPage;
