import PropTypes from "prop-types";
import React, { Component } from "react";
import VeteransAffairsHeader from "../../../va/VeteransAffairsHeader";

function supportsDetails() {
  return document.createElement("details").open !== undefined;
}

class SiteHeader extends Component {
  static propTypes = {
    title: PropTypes.string
  };

  constructor() {
    super();
    this.state = {
      open: false
    };
  }

  edgeToggleDetails = () => {
    if (!supportsDetails()) {
      this.setState({ open: !this.state.open });
    }
  };

  handleLogout = () => this.props.logout();

  render = () => {
    return <VeteransAffairsHeader />;
  };
}

export default SiteHeader;
