import React from "react";
import { Switch } from "react-router-dom";

import RouteWithHeader from "../tools/RouteWithHeader";
import _404Page from "../pages/404Page";
import LandingPage from "../pages/LandingPage";

function App() {
  return (
    <Switch>
      <RouteWithHeader key="home" path="/" exact render={LandingPage} />
      <RouteWithHeader path="*" render={_404Page} />
    </Switch>
  );
}

export default App;
