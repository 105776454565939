import { useState, useCallback } from "react";
import validateAllFields from "./validateAllFields";

const useForm = (required_fields, callback) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [fileUpload, setFileUpload] = useState(null);
  const [formIsValid, setFormIsValid] = useState({});

  const handleSubmit = (event) => {
    if (event) event.preventDefault();

    const valuesAreValid = validateValues(values);

    if (valuesAreValid) {
      const { veteranFirstName, veteranLastName, fileNumber, zipCode } = values;
      const metadata = {
        veteranFirstName,
        veteranLastName,
        fileNumber,
        zipCode
      };
      callback(metadata, fileUpload);
    } else {
      console.error("Form Invalid", errors);
    }
  };

  const handleChange = (name, e) => {
    const { value, dirty } = e;
    if (!dirty) {
      if (name === "isNonUSZip") {
        // setOldZipCode(values.zipCode);
        setValues((values) => ({
          ...values,
          zipCode: value ? "00000" : undefined,
          [name]: value
        }));
      } else {
        setValues((values) => ({ ...values, [name]: value }));
      }
    } else {
      validateValues(values);
    }
  };

  const validateValues = useCallback(
    (values) => {
      const validation = validateAllFields(values);
      const emptyRequiredFields = required_fields.filter(
        (field) => values[field] === undefined || !values[field].length
      );

      if (emptyRequiredFields.length) validation.isValid = false;

      // Reduce validation.errors into {field: error} object and setErrors
      setErrors(
        Object.keys(validation.errors).reduce((previous, key) => {
          return { ...previous, [key]: validation.errors[key].error };
        }, {})
      );

      setFormIsValid(validation.isValid);
      return validation.isValid;
    },
    [required_fields]
  );

  return {
    handleChange,
    handleSubmit,
    validateValues,
    formIsValid,
    values,
    errors,
    fileUpload,
    setFileUpload
  };
};

export default useForm;
